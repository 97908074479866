@import 'variables';
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';

/* You can add global styles to this file, and also import other style files */
body {
  padding: 0;
  margin: 0;
}

.mdc-icon-button {
  line-height: 1;
}

.ng-valid[required], .ng-valid.required {
  border-left: 5px solid #42A948; /* green */
}

.ng-invalid:not(form) {
  border-left: 5px solid #a94442; /* red */
}

.no-padding {

  .mat-expansion-panel-header, .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-dialog-container {
    padding: 0;
  }

  .mat-button-toggle-button .mat-button-toggle-label-content {
    padding: 0;
  }

  > .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.county-popup {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.easy-button-container .easy-button-button {
  height: 30px;
  width: 30px;
}

.collapsing {
  transition: none !important;
}

mwlResizable {
  box-sizing: border-box; // required for the enableGhostResize option to work
}

.resize-handle-top,
.resize-handle-bottom {
  position: absolute;
  height: 5px;
  cursor: row-resize;
  width: 100%;
}

.resize-handle-top {
  top: 0;
}

.resize-handle-bottom {
  bottom: 0;
}

$vertical-handle-width: 5px;

.min-width-5px {
  min-width: $vertical-handle-width;
}

.resize-handle-left,
.resize-handle-right {
  position: absolute;
  z-index: 1000;
  height: 100%;
  cursor: col-resize;
  @extend .min-width-5px;
  width: $vertical-handle-width;
  transition: 0.3s;

  &:hover {
    background-color: $info;
  }
}

.resize-handle-left {
  left: 0;
}

.resize-handle-right {
  right: 0;
}

.resizable-mat-dialog-panel .mat-dialog-container {
  overflow: hidden;
  resize: both;
}

//TODO: OverlayImage stops receiving events when "preferCanvas:true" map option is present because of the canvas in front of that image
.ldi .leaflet-pane .leaflet-overlay-pane img {
  z-index: 1000 !important;
}

.label-marker {
  color: ghostwhite;
  text-shadow: 0 0 2px black;
  white-space: nowrap !important;
  width: auto !important;
  height: auto !important;
}
